
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import HRMService from '@/services/hrm-service'
@Component({

})
export default class SalaryStatement extends Vue {
    @Prop({ type: Number, }) id!: number;
    @Prop({ type: Object }) payload!: any;

    mappingFeilds: any = {};
    monthly_addition_fields: any = [];
    monthly_deduction_fields: any = [];
    non_monthly_addition_fields: any = [];
    non_monthly_deduction_fields: any = [];
    selected_items: any = []
    total_items: any = []
    non_fixed_component: any = []

    @Watch("id")
    onIdChanged(newValue: any, oldValue: any) {
        // console.log('salary')
        this.get_data(this.id, this.payload);
    }
    get_data(id: number, payload: any) {
        HRMService.employee_salary_statement(id, payload).then((resp) => {
            this.selected_items = resp.data.result
            this.total_items = resp.data.total
            this.non_fixed_component = resp.data.non_fixed_component
        })
    }

    created() {
        HRMService.getPayrollConfig().then((resp) => {
            this.mappingFeilds = resp.data[0].fields_mapping;
            // console.log(this.mappingFeilds);
            for (const key in this.mappingFeilds) {
                const fieldData = this.mappingFeilds[key];
                if (fieldData.status === true || fieldData.status === "true") {
                    if (key.startsWith("monthly_addition")) {
                        this.monthly_addition_fields.push({
                            label: fieldData.name,
                            key: key,
                        });
                    } else if (key.startsWith("monthly_deduction")) {
                        this.monthly_deduction_fields.push({
                            label: fieldData.name,
                            key: key,
                        });
                    } else if (key.startsWith("non_monthly_addition")) {
                        this.non_monthly_addition_fields.push({
                            label: fieldData.name,
                            key: key,
                        });
                    } else if (key.startsWith("non_monthly_deduction")) {
                        this.non_monthly_deduction_fields.push({
                            label: fieldData.name,
                            key: key,
                        });
                    }
                }

            }
        });
    }


}
