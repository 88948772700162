
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Sidebar from "@/components/left-sidebar.vue"; // @ is an alias to /src
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import HRMService from "../services/hrm-service";



@Component({
  components: {
    Sidebar,
    Loader,
  },
})
export default class Payroll extends Vue {
  @Prop({ type: Number, }) id!: number;
  @Prop({ type: Object }) payload!: object;

  loading = false;
  item: any = {};
  public currentPage = 1;
  public import_file = null;
  params_date: any = {};
  import_params: any = {};
  create: any = {};
  ot: any = {};
  leave: any = {};
  late: any = {};
  emp_item: any = {};
  can_genrate = false
  total_monthly_allowance = 0
  total_monthly_deduction = 0



  onFiltered(filteredItems: any) { }

  mappingFeilds: any = {}


  mounted() {
    HRMService.getPayrollConfig().then((resp) => {
      this.mappingFeilds = resp.data[0].fields_mapping;
    });
  }
  isMonthlyAllowance(key: any) {
    const fieldData = this.mappingFeilds[key];
    return (
      (key.startsWith("monthly_addition") &&
        (fieldData.status === true || fieldData.status === "true"))
    );
  }
  isMonthlyDeduction(key: any) {
    const fieldData = this.mappingFeilds[key];
    return (
      (key.startsWith("monthly_deduction") &&
        (fieldData.status === true || fieldData.status === "true"))
    );
  }
  isNonMonthlyAllowance(key: any) {
    const fieldData = this.mappingFeilds[key];
    return (
      (key.startsWith("non_monthly_addition") &&
        (fieldData.status === true || fieldData.status === "true"))
    );
  }
  isNonMonthlyDeduction(key: any) {
    const fieldData = this.mappingFeilds[key];
    return (
      (key.startsWith("non_monthly_deduction") &&
        (fieldData.status === true || fieldData.status === "true"))
    );
  }



  get_data(id: any, payload: any) {
    this.loading = true;
    HRMService.employee_payroll_by_date(id, payload)
      .then((response) => {
        this.item = response.data.result;
        console.log(this.item)
        this.total_monthly_allowance = this.item['total_M_addition'] + this.item['total_NM_addition']
        this.total_monthly_deduction = this.item['total_M_deduction'] + this.item['total_NM_deduction']
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });

    HRMService.get_employee(id)
      .then((response) => {
        this.emp_item = response.data["employee"];
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }

  refreshList() { }
  getSlip() {
    this.can_genrate = true
    // let route: any = this.$router.resolve({
    //   name: "Payroll-Slip",
    //   params: { id: this.id.toString() },
    // });
    // window.open(route.href);
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-slip-statement");
  }
  getStatement() {
    // let route: any = this.$router.resolve({
    //   name: "Payroll-Slip",
    //   params: { id: this.id.toString() },
    // });
    // window.open(route.href);
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-salary-statement");
  }

  open_sidebar(item: any, type: any) {
    if (type == "ma") {
      this.$root.$emit("bv::toggle::collapse", "sidebar-variant-ma");
    } else if (type == "nma") {
      this.$root.$emit("bv::toggle::collapse", "sidebar-variant-nma");
    } else if (type == "pd") {
      this.$root.$emit("bv::toggle::collapse", "sidebar-variant-pd");
    }
  }

  @Watch("id")
  onModalShowChanged(newValue: any, oldValue: any) {
    this.get_data(this.id, this.payload);
    console.log('hitting')
  }


}
