
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import Sidebar from "@/components/left-sidebar.vue"; // @ is an alias to /src
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import HRMService from "../../../services/hrm-service";
import ExportOptions from "@/components/export-options.vue";
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import FilterService from "../../../services/filter-service";
import PopUp from "@/components/PopUp.vue";
import SidebarPayroll from "@/components/sidebar-payroll.vue";
import hrmService from "../../../services/hrm-service";
import buitinService from "@/services/buitin-service";
import PayrollSlip from './payslip.vue'
import SalaryStatement from './salary-statement.vue'
import utils from "@/utils/utils"
import AccountService from '@/services/account-service'

interface DataItem {
  month: string;
  year: string;
}
@Component({
  components: {
    Header,
    Sidebar,
    Loader,
    PopUp,
    ExportOptions,
    FilterComponent,
    SidebarPayroll,
    PayrollSlip,
    SalaryStatement,

  },
})
export default class Payroll extends Vue {
  loading = false;
  error: any = "";
  public columns = [

    { label: "Name", field: "title" },
    { label: "Attendance Days", field: "days_present" },
    { label: "Absence Days", field: "absent_days" },
    { label: "Basic", field: "basic" },
    { label: "Total Monthly Allowances", field: "total_M_addition" },
    { label: "Total Monthly Salary", field: "total_monthly_salary" },
    { label: "Absence Amount", field: "absence_amount" },
    { label: "Net Pay After Absence", field: "netpay_af_absence" },
    { label: "Total Monthly Deduction", field: "total_M_deduction" },
    { label: "over time 1 hour rate", field: "ot_rate" },
    { label: "over time approved hours", field: "ot_approved_hr" },
    { label: "over time total amount", field: "ot_total_amount" },
    { label: "Gross Salary", field: "gross_salary" },
    { label: "Total Non Monthly Allowances", field: "total_NM_addition" },
    { label: "Total Non Monthly Deduction", field: "total_NM_deduction" },
    { label: "Net Salary", field: "net_salary" },
  ];

  advance_filter_fields = [
    {
      type: "text",
      label: "Code",
      name: "code",
      value: "",
      param_name: "payroll_code",
      filter_type: "advance",
    },
    {
      type: "text",
      label: "Title",
      name: "title",
      value: "",
      param_name: "payroll_title",
      filter_type: "advance",
    },
    {
      type: "text",
      label: "Present Days",
      name: "days_present",
      value: "",
      param_name: "days_present",
      filter_type: "advance",
    },
    {
      type: "text",
      label: "Absent Days",
      name: "absent_days",
      value: "",
      param_name: "absent_days",
      filter_type: "advance",
    },
    {
      type: "text",
      label: "Basic",
      name: "basic",
      value: "",
      param_name: "basic",
      filter_type: "advance",
    },
    {
      type: "range",
      label: "Basic",
      min_param_name: "start_basic",
      max_param_name: "end_basic",
      value_2: [0, 50000],
      min: 0,
      max: 100000,
      filter_type: "advance",
    },
    {
      type: "text",
      label: "Total Monthly Allowances",
      name: "total_M_addition",
      value: "",
      param_name: "total_M_addition",
      filter_type: "advance",
    },
    {
      type: "range",
      label: "Total Monthly Allowances",
      min_param_name: "start_total_M_addition",
      max_param_name: "end_total_M_addition",
      value_2: [0, 50000],
      min: 0,
      max: 100000,
      filter_type: "advance",
    },
    {
      type: "text",
      label: "Total Monthly Salary",
      name: "total_monthly_salary",
      value: "",
      param_name: "total_monthly_salary",
      filter_type: "advance",
    },
    {
      type: "range",
      label: "Total Monthly Salary",
      min_param_name: "start_total_monthly_salary",
      max_param_name: "end_total_monthly_salary",
      value_2: [0, 50000],
      min: 0,
      max: 100000,
      filter_type: "advance",
    },

    {
      type: "text",
      label: "Absence Amount",
      name: "absence_amount",
      value: "",
      param_name: "absence_amount",
      filter_type: "advance",
    },
    {
      type: "range",
      label: "Absence Amount",
      min_param_name: "start_absence_amount",
      max_param_name: "end_absence_amount",
      value_2: [0, 50000],
      min: 0,
      max: 100000,
      filter_type: "advance",
    },
    {
      type: "text",
      label: "Net Pay After Absence",
      name: "netpay_af_absence",
      value: "",
      param_name: "netpay_af_absence",
      filter_type: "advance",
    },
    {
      type: "range",
      label: "Net Pay After Absence",
      min_param_name: "start_netpay_af_absence",
      max_param_name: "end_netpay_af_absence",
      value_2: [0, 50000],
      min: 0,
      max: 100000,
      filter_type: "advance",
    },
    {
      type: "text",
      label: "Total Monthly Deduction",
      name: "total_M_deduction",
      value: "",
      param_name: "total_M_deduction",
      filter_type: "advance",
    },
    {
      type: "range",
      label: "Total Monthly Deduction",
      min_param_name: "start_total_M_deduction",
      max_param_name: "end_total_M_deduction",
      value_2: [0, 50000],
      min: 0,
      max: 100000,
      filter_type: "advance",
    },
    {
      type: "text",
      label: "Over time 1 hour rate",
      name: "ot_rate",
      value: "",
      param_name: "ot_rate",
      filter_type: "advance",
    },
    {
      type: "range",
      label: "Over time 1 hour rate",
      min_param_name: "start_ot_rate",
      max_param_name: "end_ot_rate",
      value_2: [0, 50000],
      min: 0,
      max: 100000,
      filter_type: "advance",
    },
    {
      type: "text",
      label: "Over time approved hours",
      name: "ot_approved_hr_si",
      value: "",
      param_name: "ot_approved_hr",
      filter_type: "advance",
    },
    {
      type: "range",
      label: "Over time approved hours",
      min_param_name: "start_ot_approved_hr",
      max_param_name: "end_ot_approved_hr",
      value_2: [0, 50000],
      min: 0,
      max: 100000,
      filter_type: "advance",
    },
    {
      type: "text",
      label: "Over time total amount",
      name: "ot_approved_hr",
      value: "",
      param_name: "ot_approved_hr",
      filter_type: "advance",
    },
    {
      type: "range",
      label: "Over time total amount",
      min_param_name: "start_ot_total_amount",
      max_param_name: "end_ot_total_amount",
      value_2: [0, 50000],
      min: 0,
      max: 100000,
      filter_type: "advance",
    },
    {
      type: "text",
      label: "Gross Salary",
      name: "gross_salary",
      value: "",
      param_name: "gross_salary",
      filter_type: "advance",
    },
    {
      type: "range",
      label: "Gross Salary",
      min_param_name: "start_gross_salary",
      max_param_name: "end_gross_salary",
      value_2: [0, 50000],
      min: 0,
      max: 100000,
      filter_type: "advance",
    },
    {
      type: "text",
      label: "Total Non Monthly Allowances",
      name: "total_NM_addition",
      value: "",
      param_name: "total_NM_addition",
      filter_type: "advance",
    },
    {
      type: "range",
      label: "Total Non Monthly Allowances",
      min_param_name: "start_total_NM_addition",
      max_param_name: "end_total_NM_addition",
      value_2: [0, 50000],
      min: 0,
      max: 100000,
      filter_type: "advance",
    },
    {
      type: "text",
      label: "Total Non Monthly Deduction",
      name: "total_NM_deduction",
      value: "",
      param_name: "total_NM_deduction",
      filter_type: "advance",
    },
    {
      type: "range",
      label: "Total Non Monthly Deduction",
      min_param_name: "start_total_NM_deduction",
      max_param_name: "end_total_NM_deduction",
      value_2: [0, 50000],
      min: 0,
      max: 100000,
      filter_type: "advance",
    },
    {
      type: "text",
      label: "Net Salary",
      name: "net_salary",
      value: "",
      param_name: "net_salary",
      filter_type: "advance",
    },
    {
      type: "range",
      label: "Net Salary",
      min_param_name: "start_net_salary",
      max_param_name: "end_net_salary",
      value_2: [0, 50000],
      min: 0,
      max: 100000,
      filter_type: "advance",
    },

  ];
  public items: any = [];
  public currentPage = 1;
  public import_file = null;
  public import_file_response = "";
  params_date: any = {};
  import_params: any = {};
  create: any = {};
  ot: any = {};
  leave: any = {};

  late: any = {};
  public selected_items: any = [];
  public payrollUploaded: DataItem[] = [];
  // public forUploaded = true;
  // public selected_items = [];
  // public items = [];
  // public currentPage = 1;
  totalEmployees = ''
  get rows() {
    return this.selected_items.length;
  }
  getLogs(month: any, year: any) {
    // console.log(this.import_params.year);
    if (
      this.import_params.year !== undefined &&
      this.import_params.year !== null &&
      this.import_params.month !== null &&
      this.import_params.month !== undefined
    ) {
      hrmService
        .getPayrollLogs(month, year)
        .then((response) => {
          this.payrollUploaded = response.data.result;
          this.checkPayrollUploaded(response.data.result)

          // console.log(this.payrollUploaded);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  mappingFeilds: any = {};
  monthly_addition_fields: any = [];
  monthly_deduction_fields: any = [];
  non_monthly_addition_fields: any = [];
  non_monthly_deduction_fields: any = [];

  monthly_addition_filter: any = [];
  monthly_deduction_filter: any = [];
  non_monthly_addition_filter: any = [];
  non_monthly_deduction_filter: any = [];

  monthly_addition_col: any = [];
  monthly_deduction_col: any = [];
  non_monthly_addition_col: any = [];
  non_monthly_deduction_col: any = [];


  created() {
    hrmService.getPayrollConfig().then((resp) => {
      this.mappingFeilds = resp.data[0].fields_mapping;
      // console.log(this.mappingFeilds);
      for (const key in this.mappingFeilds) {
        const fieldData = this.mappingFeilds[key];
        if (fieldData.status === true || fieldData.status === "true") {
          if (key.startsWith("monthly_addition")) {
            this.monthly_addition_fields.push({
              label: fieldData.name,
              key: key,
            });
            this.monthly_addition_col.push({
              label: fieldData.name,
              field: key,
            });
            this.monthly_addition_filter.push(
              {
                type: "text",
                label: fieldData.name,
                name: `${key}`,
                value: "",
                param_name: `${key}`,
                filter_type: "advance",
              },
              {
                type: "range",
                label: fieldData.name,
                min_param_name: `start_${key}`,
                max_param_name: `end_${key}`,
                value_2: [0, 50000],
                min: 0,
                max: 100000,
                filter_type: "advance",
              },
            )
          } else if (key.startsWith("monthly_deduction")) {
            this.monthly_deduction_fields.push({
              label: fieldData.name,
              key: key,
            });
            this.monthly_deduction_col.push({
              label: fieldData.name,
              field: key,
            });
            this.monthly_deduction_filter.push(
              {
                type: "text",
                label: fieldData.name,
                name: `${key}`,
                value: "",
                param_name: `${key}`,
                filter_type: "advance",
              },
              {
                type: "range",
                label: fieldData.name,
                min_param_name: `start_${key}`,
                max_param_name: `end_${key}`,
                value_2: [0, 50000],
                min: 0,
                max: 100000,
                filter_type: "advance",
              },
            )
          } else if (key.startsWith("non_monthly_addition")) {
            this.non_monthly_addition_fields.push({
              label: fieldData.name,
              key: key,
            });
            this.non_monthly_addition_col.push({
              label: fieldData.name,
              field: key,
            });
            this.non_monthly_addition_filter.push(
              {
                type: "text",
                label: fieldData.name,
                name: `${key}`,
                value: "",
                param_name: `${key}`,
                filter_type: "advance",
              },
              {
                type: "range",
                label: fieldData.name,
                min_param_name: `start_${key}`,
                max_param_name: `end_${key}`,
                value_2: [0, 50000],
                min: 0,
                max: 100000,
                filter_type: "advance",
              },
            )
          } else if (key.startsWith("non_monthly_deduction")) {
            this.non_monthly_deduction_fields.push({
              label: fieldData.name,
              key: key,
            });
            this.non_monthly_deduction_col.push({
              label: fieldData.name,
              field: key,
            });
            this.non_monthly_deduction_filter.push(
              {
                type: "text",
                label: fieldData.name,
                name: `${key}`,
                value: "",
                param_name: `${key}`,
                filter_type: "advance",
              },
              {
                type: "range",
                label: fieldData.name,
                min_param_name: `start_${key}`,
                max_param_name: `end_${key}`,
                value_2: [0, 50000],
                min: 0,
                max: 100000,
                filter_type: "advance",
              },
            )
          }
        }
      }
      this.columns.splice(4, 0, ...this.monthly_addition_col);
      this.columns.splice(8 + this.monthly_addition_col.length, 0, ...this.monthly_deduction_col);
      this.columns.splice(13 + this.monthly_addition_col.length + this.monthly_deduction_col.length, 0, ...this.non_monthly_addition_col);
      this.columns.splice(14 + this.monthly_addition_col.length + this.monthly_deduction_col.length + this.non_monthly_addition_col.length, 0, ...this.non_monthly_deduction_col);
      this.advance_filter_fields.splice(6, 0, ...this.monthly_addition_filter);
      this.advance_filter_fields.splice(14 + this.monthly_addition_filter.length, 0, ...this.monthly_deduction_filter);
      this.advance_filter_fields.splice(24 + this.monthly_addition_filter.length + this.monthly_deduction_filter.length, 0, ...this.non_monthly_addition_filter);
      this.advance_filter_fields.splice(26 + this.monthly_addition_filter.length + this.monthly_deduction_filter.length + this.non_monthly_addition_filter.length, 0, ...this.non_monthly_deduction_filter);

    });
  }

  paginatedItems() {
    const start = (this.currentPage - 1) * 20;
    const end = start + 20;
    return this.selected_items.slice(start, end);
  }

  getMappedObject() { }

  for_genrate = false;
  for_confirm = false;
  for_posting = false;
  checkPayrollUploaded(data: any) {
    // console.log(data)
    this.for_genrate = false;
    this.for_confirm = false;
    this.for_posting = false;
    for (const item of data) {
      if (item.import_type === "Uploaded Payroll") {
        this.for_genrate = true; // Update the variable based on the import_type
      }
      if (item.import_type === "Genrated Payroll") {
        this.for_confirm = true; // Update the variable based on the import_type
      }
      if (item.import_type === "Confirm Payroll") {
        this.for_posting = true
      }

    }


  }

  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  saveData = true;

  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalSimpleMessage = "";
    this.modalType = "";
  }

  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end

  onFiltered(filteredItems: any) { }

  mounted() {
    const currentDate = new Date();

    // Extract the current year and month
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are zero-based, so add 1

    // Call the function to send the month and year
    this.getLogs(month, year);
    this.import_params.year = year;
    this.import_params.month = month;

  }

  get_data(params: any) {
    this.loading = true;
    HRMService.get_employee_payroll(params)
      .then((response) => {
        this.items = response.data.result;
        this.selected_items = this.items;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }

  confirmPayroll(params: any) {
    AccountService.getAccountMapping().then((resp) => {
      // console.log(resp)
      if (resp?.data?.length > 0) {
        const accruedSalariesAccountItem = resp?.data.find((item: any) => item.title === "Accrued Salaries Account");
        if (accruedSalariesAccountItem && accruedSalariesAccountItem.account !== null) {
          this.loading = true;

          AccountService.confirmPayroll(params)
            .then((response) => {
              this.getLogs(params.month, params.year)
              this.showModal('JV-1 has been created"', [], "success");
              this.loading = false;
            })
            .catch((e) => {
              this.loading = false;
              console.log(e);
              this.showModal("Not generated something went wrong", [], "error");
            });
        } else {
          this.showModal("Please Select The Accrued Salaries Account From Account Settings", [], "error");
        }

      }
      else { this.showModal("Please Select The Accrued Salaries Account From Account Settings", [], "error"); }
    })


  }
  postPayroll(params: any) {
    this.loading = true;
    AccountService.postPayroll(params)
      .then((response) => {
        this.getLogs(params.month, params.year)
        this.showModal("Payroll has been posted", [], "success");
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
        this.showModal("Not generated something went wrong", [], "error");
      });
  }
  create_payroll(params: any) {
    this.loading = true;
    HRMService.post_employee_payroll(params)
      .then((response) => {
        this.getLogs(params.month, params.year)

        this.showModal("Payroll generated", [], "success");
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
        this.showModal("Not generated something went wrong", [], "error");
      });
  }

  submit_import(params: any) {
    this.error = ""
    const error_list = utils.getEmptyKeys(params, ['year', 'month'])
    if (error_list.length > 0 || this.import_file === null) {
      this.error = utils.generateErrorMessage(error_list.concat(this.import_file === null ? 'File' : ''))
    }
    else {
      const isValidFile = this.validateFile(this.import_file);
    if(!isValidFile){
      this.showModal("Invalid file. Please upload a valid file by download the sample.",[],"error")
    }else {
      this.loading = true;
      HRMService.post_payroll_file(this.import_file, params)
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.message === "Payroll already exist") {
            this.showModal(
              "Payroll already exist please generate new one", [],
              "error"
            );
          } else if (
            response.data.message ===
            "Attendence Not Present Please generate attendance before genrating payroll"
          ) {
            this.showModal(
              "Attendence Not Present Please generate attendance before generating payroll", [],
              "error"
            );
          } else if (response.data.message === "Done") {
            this.import_file_response = response.data.message;
            this.showModal("Import Successfully", [], "success");
            this.import_file = null;
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
      }
    }
  }

  refreshList() { }
  itemId = 0;
  payload_slip = {
    month: "",
    year: ""
  }
  onRowClicked(item: any) {

    this.itemId = item.employee;
    this.payload_slip.month = item.month;
    this.payload_slip.year = item.year;

    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-payroll");
  }

  filter_fields = [
    {
      type: "dropdown",
      label: "Select Year",
      name: "year",
      value: "",
      options: ["2023", "2022", "2021"],
      param_name: "payroll_year",
      filter_type: "simple",
    },
    {
      type: "dropdown",
      label: "Select Month",
      name: "month",
      value: "",
      param_name: "payroll_month",
      options: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      filter_type: "simple",
    },
  ];

  myObj: any = {};
  key: any = "";
  value: any = "";
  monthMap: any = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };

  handleFilterChange(element: any) {
    if (element.filter_type === "simple") {
      interface SimpleObj {
        [key: string]: any;
      }
      const simpleFilteredObj: SimpleObj = {};
      this.filter_fields.forEach((filter) => {
        if (element.label === "Select Month") {
          if (filter.param_name === "payroll_month") {
            simpleFilteredObj[filter.param_name] = this.monthMap[filter.value];
          } else if (filter.param_name === "payroll_year") {
            simpleFilteredObj[filter.param_name] = filter.value;
          }
        } else {
          simpleFilteredObj[filter.param_name] = filter.value;
        }
      });
      if (
        simpleFilteredObj["payroll_month"] != "" &&
        simpleFilteredObj["payroll_year"] != ""
      ) {
        this.loading = true;
        FilterService.getAll(simpleFilteredObj).then((data) => {
          if (
            data.data.employees_payroll !== undefined &&
            data.data.employees_payroll !== null
          ) {
            this.loading = false;
            this.selected_items = data.data.employees_payroll;
            console.log(this.selected_items)
            this.totalEmployees = this.selected_items.length
          }

        });
      }
    } else if (element.filter_type === "advance") {
      interface AdvanceObj {
        [key: string]: any;
      }
      const advanceFilteredObj: AdvanceObj = {};
      this.advance_filter_fields.forEach((filter) => {
        if (filter.type === "text") {
          if (filter.param_name) {
            advanceFilteredObj[filter.param_name] = filter.value;

          }
        }
        if (filter.type === "range") {
          if (filter.min_param_name && filter.value_2) {
            advanceFilteredObj[filter.min_param_name] =
              filter.value_2[0] === 0 ? "" : filter.value_2[0];
          }
          if (filter.max_param_name && filter.value_2) {
            advanceFilteredObj[filter.max_param_name] =
              filter.value_2[1] === 0 ? "" : filter.value_2[1];
          }
        }
      });
      this.loading = true;
      FilterService.getAll(advanceFilteredObj).then((data) => {
        if (
          data.data.employees_payroll !== undefined &&
          data.data.employees_payroll !== null
        ) {
          this.loading = false;
          this.selected_items = data.data.employees_payroll;
          // console.log(this.selected_items)
        }
        // else{
        //   this.retrieve()
        // }basic
      });
    }
  }
  updateFilterFields(fields: any[]) {
    this.filter_fields = fields;
  }
  refreshDateFeild(element: any) {
    // this.retrieve();
    element.range = [];
  }
  refreshFilters() {
    for (let field of this.filter_fields) {
      field.value = "";
    }
  }
  refreshAdvanceFilters() {
    for (let field of this.advance_filter_fields) {
      if (field.type == "text" || field.type == "dropdown") {
        field.value = "";
      } else if (field.type == "range") {
        field.value_2 = [];
      }
    }
  }

  downloadSample() {
    const payload: any = {
      type: "bulk_import_payroll_document",
    };
    buitinService.getSheetSamples(payload).then((response) => {
      if (response.status === 200) {
        if (response.data.result[0].builtin_document !== undefined) {
          const anchor = document.createElement("a");
          const fileUrl = process.env.VUE_APP_SERVER + response.data.result[0].builtin_document;
          anchor.href = fileUrl
          anchor.download = "/bulk_import_payroll_document";
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        }
        this.showModal('File Has Been Downloaded', [], "success");
      }
      else if (response.status === 204) {
        this.showModal(response?.data?.result, [], "error");
      }
    }).catch((err) => {
      if (err?.response?.data?.result) {
        this.showModal(err?.response?.data?.result, [], "error");
      }
      else {
        this.showModal('Something Went Wrong', [], "error");
      }
    })
  }
  validateFile(file:any) {
      // Check if a file is selected
      if (!file) {
        return null;
      }
      // Get the file name
      const fileName = file.name;

      // Define allowed file extensions (PDF and Excel)
      const allowedExtensions = ['.xls', '.xlsx','.ods','.xlsm,','.xlsb','.xltx','.xltm','.xlam'];
      // Check if the file has an allowed extension
      const isValid = allowedExtensions.some(extension => fileName.toLowerCase().endsWith(extension));
      return isValid ? true : false;
    }
}
